import React from 'react';
import styled from 'styled-components';
import {
  Container,
  H1,
  H3,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Seo } from 'components';
import { NAME } from 'config';
import { secondary } from 'styles/colors';

const TermsOfServices: React.FC = React.memo(() => {
  const { isMobile } = useQuery();
  return (
    <>
      <Seo title={`General terms and conditions | ${NAME}`}>
        <meta name="robots" content="noindex" />
      </Seo>
      <Container>
        <H1
          margin={isMobile ? '1.5rem 0' : '3rem 0'}
          textAlign={!isMobile && 'center'}
        >
          General Terms and Conditions
        </H1>
        <H3Styled>1. Introduction</H3Styled>
        <RegularStyled>
          1.1. These General Terms and Conditions (the “T&amp;Cs”) governs the
          entire relationship between you the Client and “InnoDiets”, UAB, code
          305493571, with our registered office at Tarpupio str. 29, Mamavys,
          LT-21169 Trakų distr., the Republic of Lithuania, who provides
          Services via the Website, together with associated company UAB
          &ldquo;Kilo grupe&rdquo;, company code 303157579, registered address
          at Antakalnio str. 17, LT-10312 Vilnius, Lithuania, where it is
          related to the provision of payment collection services.
        </RegularStyled>
        <RegularStyled>
          1.2. Before the Distance contract is concluded, the Client will be
          provided with the text of this Agreement electronically or in other
          durable format. If this is not reasonably possible, the Company will
          indicate, before the Distance contract is concluded, in what way this
          Agreement is available for Client`s review at the Company`s premises
          and that they will be sent free of charge to the Client, as soon as
          possible, at the Client`s request.
        </RegularStyled>
        <RegularStyled>
          1.3. THE CLIENT IS OBLIGED TO CAREFULLY READ THIS AGREEMENT BEFORE
          ACCEPTING IT AND USING THE SERVICES OF THE COMPANY. THE CLIENT AGREES
          THAT HIS/HER USE OF THE SERVICES ACKNOWLEDGES THAT THE CLIENT HAS READ
          THIS AGREEMENT, UNDERSTOOD IT, AND AGREED TO BE BOUND BY IT.
        </RegularStyled>
        <RegularStyled>
          1.4. This Agreement contains a mandatory arbitration provision that,
          as further set forth in Section 17 below, requires the use of
          arbitration on an individual basis to resolve disputes, rather than
          jury trials or any other court proceedings, or class actions of any
          kind.
        </RegularStyled>
        <H3Styled>2. Definitions</H3Styled>
        <RegularStyled>
          2.1. Some terms are defined in the introductory part of this
          Agreement. Unless this Agreement provide otherwise, wherever used in
          this Agreement, including the introductory part, the following terms
          when capitalized shall have the following meanings:
        </RegularStyled>
        <RegularStyled>
          (a) <b>Agreement </b> Agreement for providing Services concluded
          online by the Company and the Client.
        </RegularStyled>
        <RegularStyled>
          (b) <b>Client</b> user of the Company`s Services as explained in this
          Agreement.
        </RegularStyled>
        <RegularStyled>
          (c) <b>Company</b> UAB “InnoDiets”, company code 305493571, registered
          address at Tarpupio str. 29, Mamavys, LT-21169 Trakų distr., office
          address at Antakalnio str. 17, LT-10312 Vilnius, e-mail:{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>, a
          company incorporated under the laws of Lithuania, data about the
          company is stored and kept with the Register of Legal Entities of the
          Republic of Lithuania.
        </RegularStyled>
        <RegularStyled>
          (d) <b>Offer</b> the offer to enter into this Agreement of provided by
          Company to the Client through the Website.
        </RegularStyled>
        <RegularStyled>
          (e) <b>Privacy Policy</b> the privacy policy of the Company published
          on the Website.
        </RegularStyled>
        <RegularStyled>
          (f) <b>Services</b> the Digital content provided by the Company to the
          Client as well as the accessibility to the Website, including
          information, text, images offered or provided there.
        </RegularStyled>
        <RegularStyled>
          (g) <b>Digital content</b> individual digital content sold from time
          to time online by the Company.
        </RegularStyled>
        <RegularStyled>
          (h) <b>Distance contract</b> a contract concluded between the Company
          and the Client within framework of system organized for the distance
          sale of Digital content.
        </RegularStyled>
        <RegularStyled>
          (i) <b>Website</b> the website of the Company available at
          www.zerosmokes.com,
        </RegularStyled>
        <H3Styled>3. Submission of the Offer</H3Styled>
        <RegularStyled>
          3.1. The Company will provide the Client with a possibility of
          receiving an Offer.
        </RegularStyled>
        <RegularStyled>
          3.2. The Client will be asked to provide certain information through
          before receiving the Offer by choosing provided options or typing
          requested details. The Client is obliged to provide current, correct
          and comprehensive information that is requested to be provided.
        </RegularStyled>
        <RegularStyled>
          3.3. Upon submission of the information established in Section 3.2 of
          this Agreement, the Client will be provided with the Offer. The Offer
          will include information on the following:
        </RegularStyled>
        <RegularStyled>
          3.3.1. payment amount for the relevant Services,
        </RegularStyled>
        <RegularStyled>
          3.3.2. payment options: via credit card or other allowable payment
          form,
        </RegularStyled>
        <RegularStyled>
          3.3.3. other information Company finds important to include in the
          Offer.
        </RegularStyled>
        <RegularStyled>3.4. Accepting the Offer</RegularStyled>
        <RegularStyled>
          3.4.1. The Client accepts the Offer once he/she ticks the box &ldquo;I
          agree with the Terms &amp; Conditions&rdquo;. Once the Client agrees
          with the Terms &amp; Conditions, the Client will be required to press
          the button &ldquo;Submit secure payment&rdquo;.
        </RegularStyled>
        <H3Styled>4. Distance contract</H3Styled>
        <RegularStyled>
          4.1. The Distance contract will be concluded at the moment when the
          Client accepts the Offer and as indicated in paragraph 3.4.1.
        </RegularStyled>
        <RegularStyled>
          4.2. As the Client will accept the Offer electronically, the Company
          will confirm receipt of acceptance of the Offer electronically. In
          case the Client purchases Digital content, such will be provided to
          the Client`s e-mail address provided by the Client.
        </RegularStyled>
        <RegularStyled>
          4.3. IN CASE THE AGREEMENT BETWEEN THE COMPANY AND THE CLIENT CONSISTS
          OF DIGITAL CONTENT WHICH IS NOT SUPPLIED ON A TANGIBLE MEDIUM THE
          CLIENT AGREES TO LOSE HIS/HER RIGHT OF WITHDRAWAL OF THE AGREEMENT.
        </RegularStyled>
        <RegularStyled>
          4.4. The Company makes reasonable efforts to ensure that Services
          operate as intended, however such Services are dependent upon internet
          and other services and providers outside of the control of the
          Company. By using Company`s Services, the Client acknowledges that the
          Company cannot guarantee that Services will be uninterrupted, error
          free or that the information it contains will be entirely free from
          viruses, hackers, intrusions, unscheduled downtime or other failures.
          The Client expressly assumes the risk of using or downloading such
          Services.
        </RegularStyled>
        <RegularStyled>
          4.5. From time to time and without prior notice to the Client, we may
          change, expand and improve the Services. We may also, at any time,
          cease to continue operating part or all of the Services or selectively
          disable certain aspects of the Services. Any modification or
          elimination of the Services will be done in our sole and absolute
          discretion and without an ongoing obligation or liability to the
          Client, and the Client use of the Services do not entitle the Client
          to the continued provision or availability of the Services.
        </RegularStyled>
        <RegularStyled>4.6. The Client furthermore agrees that:</RegularStyled>
        <RegularStyled>
          4.6.1 he/she shall not access Services if he/she is under the age of
          18;
        </RegularStyled>
        <RegularStyled>
          4.6.2 The Client will deny access of Services to children under the
          age of 18. The Client accepts full responsibility for any unauthorized
          use of the Services by minors.
        </RegularStyled>
        <H3Styled>5. Payments</H3Styled>
        <RegularStyled>
          5.1. During the period of validity indicated in the Offer, the price
          for the Services being offered will not increase, except for price
          changes in VAT-tariffs.
        </RegularStyled>
        <RegularStyled>5.2. The Client agrees to:</RegularStyled>
        <RegularStyled>
          5.2.1. pay all additional costs, fees, charges, applicable taxes and
          other charges that can be incurred by the Client.
        </RegularStyled>
        <RegularStyled>
          5.2.2. purchase Services by using valid credit card or other allowed
          form of payment;
        </RegularStyled>
        <RegularStyled>
          5.2.3. provide Company current, correct and comprehensive information
          as detailed in the purchase order form. If Company discovers or
          believes that any information provided by Client is not current,
          inaccurate or incomplete, Company reserves the right to suspend the
          Service at its sole discretion and Client forfeits any right to refund
          paid amount.
        </RegularStyled>
        <RegularStyled>
          5.3. After the Client is transferred to the third party payment
          service provider, the risk of loss or damages will pass to the Client
          and/or third party service. The Client`s online credit or debit card
          payments to the Company will be handled and processed by third party
          payment service provider and none of the sensitive data in relation to
          your payment will be stored on or used by the Company. The Company
          shall not be liable for any payment issues or other disputes that
          arise due to the third party payment services. The Company may change
          the third party payment service provider from time to time.
        </RegularStyled>
        <RegularStyled>
          5.4. All prices and costs are in US Dollars unless otherwise
          indicated.
        </RegularStyled>
        <RegularStyled>
          5.5. The price applicable is that set at the date on which you place
          your order. Payment fees are recognized before confirming the
          purchase. If you are under 18 years old you must have parents&rsquo;
          permission to buy from the Company.
        </RegularStyled>
        <RegularStyled>
          5.6. All transfers conducted through the Company are handled and
          transacted through third party dedicated gateways to guarantee your
          protection. Card information is not stored and all card information is
          handled over SSL encryption. Please read the terms &amp; conditions
          for the payment gateway chosen for the transaction as they are
          responsible for the transactions made.
        </RegularStyled>
        <RegularStyled>
          5.7. Your payments are processed by Kilo grupė, UAB, company code
          303157579, registered address at Antakalnio str. 17, LT-10312 Vilnius,
          Lithuania. For a refund or a complaint, please contact{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
        </RegularStyled>
        <RegularStyled>
          5.8. In order to ensure that Client does not experience an
          interruption or loss of Services, the Services might be offered on
          automatic renewal.
        </RegularStyled>
        <RegularStyled>
          5.8.1. EXCEPT FOR REASONS DESCRIBED BELOW IN THIS SECTION, AUTOMATIC
          RENEWAL AUTOMATICALLY RENEWS THE APPLICABLE SERVICE UPON EXPIRATION OF
          THE CURRENT TERM FOR A RENEWAL PERIOD EQUAL IN TIME TO THE MOST RECENT
          SERVICE PERIOD. For example, if Clients last service period is for one
          year, the renewal period will typically be for one year.
        </RegularStyled>
        <RegularStyled>
          5.8.2. Unless Client cancels the subscription, Company will
          automatically renew the applicable service when it comes up for
          renewal and will take payment from the payment method associated with
          the Service in Client`s account.
        </RegularStyled>
        <RegularStyled>
          5.8.3. The Company may change the subscription plans and the price of
          the Services from time to time.
        </RegularStyled>
        <RegularStyled>
          5.8.4. IF CLIENT DOES NOT WISH FOR SERVICE TO AUTOMATICALLY RENEW, he
          may select to cancel the subscription at least 48 hours before the end
          of current period, in which case, the Services will be terminated upon
          expiration of the then current term, unless he manually renew the
          Services prior to that date.
        </RegularStyled>
        <RegularStyled>
          5.8.5. Client may easily cancel the subscription by contacting the
          support team by{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>.
        </RegularStyled>
        <RegularStyled>
          5.9. From time to time the Company might offer the Special Deals which
          may contain additional terms and conditions applicable together with
          this Agreement. To the extent that there is any irreconcilable
          conflict between any additional Special Deals terms and this
          Agreement, the additional Special Deals terms shall prevail.
        </RegularStyled>
        <RegularStyled>
          5.9.1. The Company may offer the trials of paid subscriptions for the
          limited time without payment or at a special price ( “Trial”). The
          Company will automatically begin charging the Client for the
          subscription on the first day following the end of the Trial on
          recurring monthly basis or another interval what Company discloses in
          the Special Deal. If Client doesn`t want to be charged, he must cancel
          the subscription before the end of the Trial.
        </RegularStyled>
        <RegularStyled>
          5.9.2. If the Client have purchased or received the promo code, gift,
          a special discount or other offer sold by the Company for access to a
          paid subscription, separate terms and conditions presented to the
          Client along with the Special Deal may also apply. The Client agree to
          comply with any such terms and conditions.
        </RegularStyled>
        <H3Styled>6. Refund Policy</H3Styled>
        <RegularStyled>
          6.1. The Company follows a no refund policy, unless the product is
          proven to be not as described or faulty. In such cases, Client must
          contact our customer support at{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link> within 14
          days upon purchase and provide detailed information proving Company’s
          product fault (with visual proof attached). Once a refund is issued,
          Client no longer has the access to Company’s product. All refunds are
          applied to the original method of payment. By purchasing Services,
          Client agrees to this refund policy and relinquishes any rights to
          subject it to any questions, judgment or legal actions.
        </RegularStyled>
        <RegularStyled>
          6.3. Once the refund form has been received and checked by our staff,
          a refund will be authorised by the same method that the payment was
          made. Please be advised that the refund may take up to 14 working days
          to reach your bank account. If the Client fails to meet the deadlines
          of our refund policy, we will be unable to offer a refund.
        </RegularStyled>
        <H3Styled>7. Intellectual Property Rights</H3Styled>
        <RegularStyled>
          7.1. As between Company and Client, all intellectual property rights,
          including but not limited to copyright, design rights, trademark
          rights, patent rights and any other proprietary rights in or to
          related to the Services and Services-related content are owned by the
          Company.
        </RegularStyled>
        <RegularStyled>
          7.2. The Client must not reproduce, disassemble, reverse engineer,
          decompile, distribute, publicly display or perform, or publish or
          otherwise make available the Services including but not limited to
          Digital content, in whole or in part without Company`s prior written
          consent.
        </RegularStyled>
        <RegularStyled>
          7.3. The Client hereby grants to the Company a perpetual, irrevocable,
          worldwide, fully paid-up and royalty‑free, non-exclusive license,
          including the right to sublicense (through multiple tiers) and assign
          to third parties, to reproduce, distribute, perform and display
          (publicly or otherwise), create derivative works of, adapt, modify and
          otherwise use, analyze and exploit in any way now known or in the
          future discovered, his/her User Content (except for User Trademarks)
          as well as all modified and derivative works thereof. To the extent
          permitted by applicable laws, the Client hereby waives any moral
          rights he/she may have in any User Content. “User Content” means any
          User Trademarks, communications, images, writings, creative works,
          sounds, and all the material, data, and information, that the Client
          uploads, transmits or submits through the Services, or that other
          users upload or transmit. By uploading, transmitting or submitting any
          User Content, the Client affirms, represents and warrants that such
          User Content and its uploading, transmission or submission is (a)
          accurate and not confidential; (b) not in violation of any applicable
          laws, contractual restrictions or other third‑party rights, and that
          the Client has permission from any third party whose personal
          information or intellectual property is comprised or embodied in the
          User Content; and (c) free of viruses, adware, spyware, worms or other
          malicious code.
        </RegularStyled>
        <RegularStyled>
          7.4. No part of this Agreement is or should be interpreted as a
          transfer of intellectual property rights in relation to the Services
          or Services-related content, except as expressly set forth in Section
          8.1 below.
        </RegularStyled>
        <H3Styled>8. Use of Digital content</H3Styled>
        <RegularStyled>
          8.1. All intellectual property rights specified in Article 7.1 and
          relating to Digital content are owned by the Company. Digital content
          is licensed pursuant to this Section 8 and is not sold. The Client
          will only be granted a limited, revocable, non-exclusive,
          non-transferable and non-sublicensable license, subject to the terms
          and conditions of this Agreement, to use (solely for the Client`s
          individual use) any Digital content provided by Company to the Client.
        </RegularStyled>
        <RegularStyled>
          8.2. The term of this license shall be for a term of 5 years from the
          date of the Client receiving the applicable Digital content, unless
          earlier suspended or terminated in accordance with this Agreement.
        </RegularStyled>
        <RegularStyled>
          8.3. Unless expressly otherwise provided, the Client must not use any
          Digital content except for personal, non-commercial purposes.
        </RegularStyled>
        <RegularStyled>
          8.4. The Client must not edit, reproduce, transmit or lend the Digital
          content or make it available to any third parties or use it to perform
          any other acts which extend beyond the scope of the license provided
          in this Section 8 by the Company.
        </RegularStyled>
        <RegularStyled>
          8.5. The Company may impose restrictions on the scope of the license
          or the number of devices or types of devices on which Digital content
          can be used.
        </RegularStyled>
        <RegularStyled>
          8.6. If the Client violates this Section 8, the Company may suspend
          access to the relevant Digital content, without limiting any of
          Company`s rights or remedies under this Agreement or applicable law,
          including Company`s right to recover from the Client the loss suffered
          as a result of or in connection with the infringement including any
          expenses incurred.
        </RegularStyled>
        <H3Styled>9. Sale of Digital Content Prohibited</H3Styled>
        <RegularStyled>
          9.1. The Client is prohibited from selling, offering for sale,
          sharing, renting out or lending Digital content, or copies of Digital
          content.
        </RegularStyled>
        <H3Styled>10. Privacy Policy</H3Styled>
        <RegularStyled>
          10.1. The processing of Client`s personal data is governed by the
          Privacy Policy. It is recommended for the Client to print and keep a
          copy of the Privacy Policy together with this Agreement.
        </RegularStyled>
        <H3Styled>11. Indemnity</H3Styled>
        <RegularStyled>
          11.1. The Client will indemnify and hold the Company, its affiliates,
          officers, directors, employees, agents, legal representatives,
          licensors, subsidiaries, joint ventures and suppliers, harmless from
          any claim or demand, including reasonable attorneys` fees, made by any
          third party due to or arising out of Client`s breach of this Agreement
          or use of the Services, or Client`s violation of any law or the rights
          of a third party in conjunction with Client`s breach of this Agreement
          or use of the Services.
        </RegularStyled>
        <H3Styled>12. Liability</H3Styled>
        <RegularStyled>
          12.1. INFORMATION MAY NOT BE APPROPRIATE OR SATISFACTORY FOR THE
          CLIENT USE, AND HE/SHE SHOULD VERIFY ALL INFORMATION BEFORE RELYING ON
          IT. ANY DECISIONS MADE BASED ON INFORMATION CONTAINED IN THE WEBSITE,
          INCLUDING INFORMATION RECEIVED THROUGH CLIENT`S USE OF THE SERVICES,
          ARE HIS/HER SOLE RESPONSIBILITY.
        </RegularStyled>
        <RegularStyled>
          12.2. THE CLIENT EXPRESSLY UNDERSTANDS AND AGREES THAT THE COMPANY
          SHALL NOT BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT
          LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
          EXEMPLARY DAMAGES, OR THOSE RESULTING FROM LOST PROFITS, LOST DATA OR
          BUSINESS INTERRUPTION, LOSS OF GOODWILL, LOSS OF USE, OR OTHER LOSSES
          WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL THEORY
          (EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES), ARISING OUT OF: (i) THE USE OR INABILITY TO USE SERVICES,
          (ii) ANY LINK PROVIDED IN CONNECTION WITH THE SERVICES, (iii) THE
          MATERIALS OR INFORMATION CONTAINED AT ANY OR ALL SUCH LINKED WEBSITES,
          (iv) CLIENT`S RELIANCE ON ANY OF THE SERVICES; (v) THE INTERRUPTION,
          SUSPENSION, TERMINATION OF THE SERVICES OR ANY PORTION THEREOF, (vi)
          THE TIMELINESS, DELETION, MISDELIVERY OR FAILURE TO POST OR STORE ANY
          INFORMATION, OR (vii) ANY MATTER OTHERWISE RELATED TO THE CLIENT`S USE
          OF THE SERVICES. IN NO EVENT SHALL THE COMPANY`S AGGREGATE LIABILITY
          TO THE CLIENT RELATING TO HIS/HER USE OF THE SERVICES EXCEED ONE
          HUNDRED DOLLARS ($100).
        </RegularStyled>
        <RegularStyled>
          12.3. A party to the Agreement shall be released from responsibility
          for non-fulfilment if it proves that this Agreement was not fulfilled
          due to force majeure. In particular, the Company shall not be liable
          for any losses caused by force majeure, riot, war or natural events or
          due to other occurrences for which the Company is not responsible
          (e.g. strike, lock-out, traffic hold-ups, administrative acts of
          domestic or foreign high authorities). The Client must provide written
          notification of the occurrence of force majeure, which prevents the
          fulfilment of this Agreement, within 30 calendar days from the date of
          the occurrence of these circumstances. The Company shall inform the
          Client about the occurrence of force majeure by e-mail or on the
          Website if possible.
        </RegularStyled>
        <RegularStyled>
          12.4. THE LIABILITY OF THE COMPANY IS LIMITED TO DIRECT LOSSES, UNLESS
          OTHERWISE PROVIDED UNDER THE APPLICABLE LAWS.
        </RegularStyled>
        <RegularStyled>
          12.5. Due to the nature of Services that the Company provides and as
          the Company cannot control the Client`s adherence to the provided use
          instructions, the Company provides no warranty as to any results or
          outcomes coming from using Services.
        </RegularStyled>
        <RegularStyled>
          12.6. When using Services the Client may receive links to other
          websites that are not owned and/or controlled by the Company. These
          are provided &ldquo;as is&rdquo;. The Client acknowledges and agrees
          that the Company is not responsible for the operation of such links.
          Furthermore, the Company is not responsible or liable for any content,
          advertising, products or other materials that may be accessed through
          such links and therefore the Client agrees that the Company shall not
          be responsible or liable, directly or indirectly for any damage or
          loss caused or alleged to be caused by or in connection with use or
          reliance on any such content, goods, services available on or through
          any such websites.
        </RegularStyled>
        <H3Styled>13. Medical disclaimer</H3Styled>
        <RegularStyled>
          13.1. BEFORE TRYING SERVICES BY THE COMPANY, THE CLIENT SHOULD CONSULT
          WITH HIS/HER HEALTHCARE SERVICE PROVIDER.
        </RegularStyled>
        <RegularStyled>
          13.2. THE COMPANY CLEARLY STATE THAT IT IS NOT A MEDICAL ORGANISATION
          AND CANNOT GIVE THE CLIENT ANY MEDICAL ADVICE, DIAGNOSIS OR
          ASSISTANCE. NOTHING WITHIN SERVICES BY THE COMPANY IS ASSOCIATED WITH,
          SHOULD BE TAKEN OR UNDERSTOOD AS MEDICAL ADVICE OR ASSISTANCE NOR
          SHOULD IT BE INTERPRETED IN SUBSTITUTION FOR ANY MEDICAL ADVICE OR
          ASSISTANCE, OR USED, REFERRED TO INSTEAD OF SEEKING APPROPRIATE
          MEDICAL ADVICE OR ASSISTANCE FROM HEALTH CARE PROVIDERS. THE CLIENT IS
          SOLELY RESPONSIBLE FOR EVALUATING AND ASSESSING HIS OWN HEALTH,
          INCLUDING ANY NEED TO SEEK APPROPRIATE GUIDANCE FROM A HEALTH CARE
          PROVIDER.
        </RegularStyled>
        <RegularStyled>
          13.3. The Company encourage the Client to seek appropriate medical
          advice or assistance before using Company`s Services.
        </RegularStyled>
        <RegularStyled>
          13.4. The Client should not disregard medical advice or delay visiting
          a medical professional because of something Client read on the
          Companies website, or on other Companies communication channels.
        </RegularStyled>
        <H3Styled>14. Validity and Termination</H3Styled>
        <RegularStyled>
          14.1. This Agreement is effective after the Client accepts and
          electronically expresses his/her consent to comply with them, and they
          shall remain in effect until terminated in accordance with the
          following section.
        </RegularStyled>
        <RegularStyled>
          14.2. The Company may terminate the relationship with the Client at
          any time in the following cases: (1) the Client does not agree with
          the Agreement; (2) the Client commits any breach of the Agreement; (3)
          the Client does not provide information requested by the Company
          and/or provides incorrect and/or incomprehensive information.
          Notwithstanding the foregoing, statutory termination rights shall not
          be affected.
        </RegularStyled>
        <H3Styled>15. Changes to Agreement</H3Styled>
        <RegularStyled>
          15.1. This Agreement, Privacy Policy and any additional terms and
          conditions that may apply are subject to change. The Company reserves
          the right to modify and update the Agreement from time to time and
          such changes shall be effective immediately upon posting to the
          Company&rsquo;s Website.
        </RegularStyled>
        <RegularStyled>
          15.2. All amended Agreement, Privacy Policy and any additional terms
          and conditions will be posted online. The Company may give notice to
          the Client of any upcoming changes by sending an email to the primary
          email address provided by the Client, or notifying through the
          Website.
        </RegularStyled>
        <RegularStyled>
          15.3. The Client understands and agrees that any continued use and
          access to the Services after any posted updates of the Agreement,
          means that Client voluntary agrees to be bound by this Agreement. If
          Client does not agree to be bound by the updated Agreement, he/she
          should not use (or continue to use) the Services.
        </RegularStyled>
        <H3Styled>16. Communication</H3Styled>
        <RegularStyled>
          16.1. In general, the Company prefers communication by e-mail. By
          accepting this Agreement, the Client accepts communication by e-mail.
          For this purpose, the Client is requested to have a valid e-mail
          address and provide it when filling required information as stipulated
          in Section 3.2. The Company may publish information related to this
          Agreement or Services on the Website as well. The Client should check
          his/her e-mail messages as well as information provided on the Website
          regularly and frequently. E-mails may contain links to further
          information and documents.
        </RegularStyled>
        <RegularStyled>
          16.2. Where applicable laws require provision of information on a
          durable medium, the Company will either send the Client an email with
          an attachment or send the Client a notification referring to the
          Services with download function to retain such information and
          documents permanently for future reference. It is the Client&rsquo;s
          responsibility requested to keep copies of all communications from the
          Company.
        </RegularStyled>
        <RegularStyled>
          16.3. The Client may request a copy of this Agreement or any other
          contractual document by contacting{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
        </RegularStyled>
        <RegularStyled>
          16.4. The communication with the Client will be made in English,
          unless the Company and the Client agree to communicate in another
          language.
        </RegularStyled>
        <RegularStyled>
          16.5. The Client may contact us at any time by sending a message to{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
        </RegularStyled>
        <H3Styled>17. Dispute resolution</H3Styled>
        <RegularStyled>
          17.1. Governing Law. This Agreement is governed by the laws of Texas
          without regard to its principles of conflicts of law, and regardless
          of Client&rsquo;s location.
        </RegularStyled>
        <RegularStyled>
          17.2. Informal Dispute Resolution. Client agrees to participate in
          informal dispute resolution before filing a claim against the Company.
          Any complaints in relation to the Company and the Services provided to
          the Client should be addressed to the Company by contacting{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>. Client
          should clearly indicate that a complaint is being submitted and
          specify the grounds and circumstances concerning the complaint. The
          Company will send a complaint acknowledgement to the e-mail address
          from which the complaint has been received. We will consider the
          complaint and respond to the Client within 14 calendar days of the day
          of receipt of a relevant complaint. If a dispute is not resolved
          within 30 calendar days of the day of receipt of a relevant complaint,
          Client or Company may bring a formal claim.
        </RegularStyled>
        <RegularStyled>
          17.3. Arbitration. Except for disputes that qualify for small claims
          court, all disputes arising out of or related to this Agreement or any
          aspect of the relationship between Client and Company, whether based
          in contract, tort, statute, fraud, misrepresentation or any other
          legal theory, will be resolved through final and binding arbitration
          before a neutral arbitrator instead of in a court by a judge or jury.
          Client and Company agrees that Client and Company are each waiving the
          right to trial by a jury. Such disputes include, without limitation,
          disputes arising out of or relating to interpretation or application
          of this arbitration provision, including the enforceability,
          revocability or validity of the arbitration provision or any portion
          of the arbitration provision. All such matters shall be decided by an
          arbitrator and not by a court or judge.
        </RegularStyled>
        <RegularStyled>
          17.4. Client agrees that any arbitration under this Agreement will
          take place on an individual basis; class arbitrations and class
          actions are not permitted, and Client is agreeing to give up the
          ability to participate in a class action.
        </RegularStyled>
        <RegularStyled>
          17.5. Client may opt out of this agreement to arbitrate by emailing{' '}
          <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com </Link> with
          Client’s first name, last name, and address within thirty (30) days of
          accepting this agreement to arbitrate, with a statement that Client
          declines this arbitration agreement.
        </RegularStyled>
        <RegularStyled>
          17.6. The arbitration will be administered by the American Arbitration
          Association under its Consumer Arbitration Rules, as amended by this
          Agreement. The Consumer Arbitration Rules are available online at
          https://www.adr.org/consumer. The arbitrator will conduct hearings, if
          any, by teleconference or videoconference, rather than by personal
          appearances, unless the arbitrator determines upon request by Client
          or Company that an in-person hearing is appropriate. Any in-person
          appearances will be held at a location that is reasonably convenient
          to both parties with due consideration of their ability to travel and
          other pertinent circumstances. If the parties are unable to agree on a
          location, such determination should be made by the AAA or by the
          arbitrator. The arbitrator&rsquo;s decision will follow the terms of
          this Agreement and will be final and binding. The arbitrator will have
          authority to award temporary, interim or permanent injunctive relief
          or relief providing for specific performance of this Agreement, but
          only to the extent necessary to provide relief warranted by the
          individual claim before the arbitrator. The award rendered by the
          arbitrator may be confirmed and enforced in any court having
          jurisdiction thereof. Notwithstanding any of the foregoing, nothing in
          this Agreement will preclude Client from bringing issues to the
          attention of federal, state or local agencies and, if the law allows,
          they can seek relief against us for you.
        </RegularStyled>
        <H3Styled>18. Miscellaneous</H3Styled>
        <RegularStyled>
          18.1. No person other than the Client shall have any rights under this
          Agreement.
        </RegularStyled>
        <RegularStyled>
          18.2. Client may not assign any rights under this Agreement to any
          third party without the prior consent of the Company. The Company at
          its sole discretion may assign its rights and obligations under this
          Agreement in full or in part to any third party.
        </RegularStyled>
        <RegularStyled>
          18.3. If any part of this Agreement is found by a court of competent
          jurisdiction to be invalid, unlawful or unenforceable then such part
          shall be severed from the remainder of the Agreement, which shall
          continue to be valid and enforceable to the fullest extent permitted
          by law.
        </RegularStyled>
        <RegularStyled>
          18.4. THE USE OF THE SERVICES IS SOLELY AT CLIENT`S OWN RISK. THE
          SERVICES ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
          AVAILABLE&rdquo; BASIS. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES
          OF ANY KIND WITH RESPECT TO THE SERVICES, WHETHER EXPRESS OR IMPLIED
          INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR ARISING FROM A
          COURSE OF DEALING, USAGE OR TRADE PRACTICE. SOME STATES DO NOT ALLOW
          THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT
          APPLY TO THE CLIENT. THE COMPANY MAKES NO WARRANTY THAT THE SITE OR
          SERVICE WILL MEET CLIENT`S REQUIREMENTS, OR WILL BE UNINTERRUPTED,
          TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE OR ERROR-FREE OR THAT THE
          RESULTS THAT MAY BE OBTAINED BY USE OF THE SITE OR SERVICE WILL BE
          ACCURATE OR RELIABLE. CLIENT UNDERSTAND AND ACKNOWLEDGE THAT HIS/HER
          SOLE AND EXCLUSIVE REMEDY WITH RESPECT TO ANY DEFECT IN OR
          DISSATISFACTION WITH THE SITE OR SERVICE IS TO CEASE TO USE THE
          SERVICES. CLIENT MAY HAVE OTHER RIGHTS, WHICH MAY VARY FROM STATE TO
          STATE.
        </RegularStyled>
        <RegularStyled>
          18.5. BY USING OR ACCESING THE SERVICES, CLIENT HEREBY ACKNOWLEDGES
          THAT HE/SHE HAS READ THIS AGREEMENT, UNDERSTOOD IT, AND AGREES TO BE
          BOUND BY ITS TERMS AND CONDITIONS.
        </RegularStyled>
      </Container>
    </>
  );
});

TermsOfServices.displayName = 'Terms of services';

export default TermsOfServices;

const RegularStyled = styled(Regular)`
  margin: 0 0 1rem;
`;

const H3Styled = styled(H3)`
  margin: 0 0 1.5rem;
`;

const Link = styled.a`
  color: ${secondary};
`;
